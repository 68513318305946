

const CommunitiesLogo = () => {
  return (
    <div className="mr-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 8.76C15.9673 10.3934 14.6337 11.7003 13 11.7C11.3858 11.6734 10.0821 10.3741 10.05 8.76V7.63C10.0498 6.96439 10.3183 6.32687 10.7945 5.86189C11.2708 5.39691 11.9146 5.14383 12.58 5.16H13.52C14.1768 5.15734 14.8076 5.4164 15.273 5.8799C15.7384 6.34341 16 6.97318 16 7.63V8.76ZM12.55 6.63C11.9977 6.63 11.55 7.07772 11.55 7.63L11.59 8.76C11.59 9.56081 12.2392 10.21 13.04 10.21C13.8408 10.21 14.49 9.56081 14.49 8.76V7.63C14.49 7.07772 14.0423 6.63 13.49 6.63H12.55Z" fill="#212121"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.48 15.3L23.59 16.86C23.7056 17.3482 23.5927 17.8625 23.2834 18.2575C22.974 18.6525 22.5017 18.8852 22 18.89H17.51C17.1183 19.3075 16.5725 19.5461 16 19.55H10C9.39859 19.5581 8.82285 19.3066 8.42001 18.86C8.32696 18.8703 8.23305 18.8703 8.14001 18.86H3.55001C3.06198 18.8416 2.60718 18.6079 2.30802 18.2219C2.00885 17.8359 1.89606 17.3372 2.00001 16.86L2.15001 15.3C2.29593 14.5556 2.94161 14.0141 3.70001 14H8.00001C8.17949 13.99 8.35926 14.0138 8.53001 14.07C8.91366 13.5908 9.49615 13.3143 10.11 13.32H15.89C16.4679 13.3178 17.0186 13.5658 17.4 14C17.4697 13.9902 17.5404 13.9902 17.61 14H21.93C22.6884 14.0141 23.3341 14.5556 23.48 15.3ZM17.55 17.36H22.08C22.1106 17.3011 22.1143 17.2318 22.09 17.17V16.97L21.99 15.55L21.93 15.47H17.4C16.9773 15.4715 16.5737 15.2934 16.29 14.98C16.1908 14.8613 16.0447 14.7919 15.89 14.79H10.11C9.94248 14.7922 9.78471 14.8692 9.68001 15C9.39455 15.3389 8.97308 15.5331 8.53001 15.53C8.34858 15.5308 8.16867 15.4969 8.00001 15.43H3.75001C3.69001 15.43 3.69001 15.45 3.69001 15.51L3.59001 16.93V17.13C3.56263 17.1904 3.56263 17.2596 3.59001 17.32H8.18001C8.28645 17.3102 8.39357 17.3102 8.50001 17.32C8.9064 17.3275 9.29097 17.5053 9.56001 17.81C9.67034 17.9391 9.83023 18.0154 10 18.02H16.04C16.2101 18.0167 16.3705 17.9402 16.48 17.81C16.7612 17.5215 17.1472 17.3592 17.55 17.36Z" fill="#212121"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.97 10.26C21.97 11.475 20.985 12.46 19.77 12.46C19.1848 12.4627 18.6226 12.2321 18.2079 11.8192C17.7932 11.4063 17.56 10.8452 17.56 10.26V9.42C17.56 8.92935 17.7549 8.4588 18.1019 8.11185C18.4488 7.76491 18.9194 7.57 19.41 7.57H20.12C21.1417 7.57 21.97 8.39827 21.97 9.42V10.26ZM19.1625 9.21251C19.0969 9.27815 19.06 9.36717 19.06 9.46V10.3C19.1026 10.6571 19.4054 10.9259 19.765 10.9259C20.1246 10.9259 20.4274 10.6571 20.47 10.3V9.46C20.47 9.2667 20.3133 9.11 20.12 9.11H19.41C19.3172 9.11 19.2282 9.14688 19.1625 9.21251Z" fill="#212121"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.42211 11.8192C7.00737 12.2321 6.44522 12.4627 5.86001 12.46C5.27653 12.46 4.71695 12.2282 4.30437 11.8156C3.89179 11.4031 3.66001 10.8435 3.66001 10.26V9.42C3.66001 8.39827 4.48828 7.57 5.51001 7.57H6.22001C7.24173 7.57 8.07001 8.39827 8.07001 9.42V10.26C8.07001 10.8452 7.83686 11.4063 7.42211 11.8192ZM5.51001 9.11C5.31671 9.11 5.16001 9.2667 5.16001 9.46V10.3C5.20261 10.6571 5.50542 10.9259 5.86501 10.9259C6.2246 10.9259 6.52741 10.6571 6.57001 10.3V9.46C6.57001 9.2667 6.41331 9.11 6.22001 9.11H5.51001Z" fill="#212121"/>
      </svg>
    </div>
  )
}

export default CommunitiesLogo;