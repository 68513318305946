

const ExploreLogo = () => {
  return (
    <div className="mr-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.2187 7.88956C15.1274 7.87883 15.0352 7.87883 14.944 7.88956L9.97731 9.17518C9.58192 9.2732 9.2732 9.58192 9.17518 9.97731L7.92253 14.9879C7.83992 15.3214 7.91808 15.6744 8.13378 15.9419C8.34948 16.2093 8.67788 16.3605 9.02134 16.3504C9.1126 16.3612 9.20479 16.3612 9.29605 16.3504L14.3176 15.0648C14.713 14.9668 15.0217 14.6581 15.1198 14.2627L16.3724 9.2521C16.455 8.91857 16.3769 8.56559 16.1612 8.29812C15.9455 8.03065 15.6171 7.87949 15.2736 7.88956H15.2187ZM14.01 10.1861L13.2408 13.2847L10.1421 14.0539L10.9113 10.9553L14.01 10.1861Z" fill="#212121"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.4495 1H9.79051C4.93565 1 1 4.93565 1 9.79051V14.4495C1 19.3044 4.93565 23.24 9.79051 23.24H14.4495C19.3044 23.24 23.24 19.3044 23.24 14.4495V9.79051C23.24 4.93565 19.3044 1 14.4495 1ZM9.79051 21.5918C5.84844 21.5857 2.65427 18.3916 2.64822 14.4495V9.79051C2.65427 5.84844 5.84844 2.65427 9.79051 2.64822H14.4495C18.3916 2.65427 21.5857 5.84844 21.5918 9.79051V14.4495C21.5857 18.3916 18.3916 21.5857 14.4495 21.5918H9.79051Z" fill="#212121"/>
      </svg>
    </div>
  )
}

export default ExploreLogo;