

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
      <path d="M35.904 29.28L42.624 22.56C42.624 17.952 41.472 13.536 39.36 9.69598L23.712 24.192V48.288H26.688C37.152 48.288 45.696 39.84 45.696 29.28H35.904Z" fill="#394AE2"/>
      <path d="M11.712 19.008L4.992 25.728C4.992 30.336 6.144 34.752 8.256 38.592L23.904 24.096V0H20.928C10.464 0 1.92 8.448 1.92 19.008L11.712 10V19.008Z" fill="#394AE2"/>
    </svg>
  )
}

export default Logo;