

const Ava = () => {
  return (
    <div className="bg-[#F3F3F8] flex items-center justify-center w-[50px] h-[50px] rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 26 26" fill="none">
        <path d="M17.8333 23.4167H8.16667C7.11372 23.3784 6.13288 22.8719 5.49203 22.0356C4.85117 21.1992 4.61726 20.1204 4.85417 19.0937L5.10417 17.9062C5.3917 16.2987 6.77356 15.1174 8.40625 15.0833H17.5938C19.2264 15.1174 20.6083 16.2987 20.8958 17.9062L21.1458 19.0937C21.3827 20.1204 21.1488 21.1992 20.508 22.0356C19.8671 22.8719 18.8863 23.3784 17.8333 23.4167Z" fill="#818199"/>
        <path d="M13.5208 13H12.4792C10.178 13 8.3125 11.1345 8.3125 8.83333V6.08333C8.30972 5.15422 8.67758 4.26237 9.33456 3.60539C9.99154 2.94841 10.8834 2.58055 11.8125 2.58333H14.1875C15.1166 2.58055 16.0085 2.94841 16.6654 3.60539C17.3224 4.26237 17.6903 5.15422 17.6875 6.08333V8.83333C17.6875 9.9384 17.2485 10.9982 16.4671 11.7796C15.6857 12.561 14.6259 13 13.5208 13Z" fill="#818199"/>
      </svg>
    </div>
  )
}

export default Ava;