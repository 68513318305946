

const JobsLogo = () => {
  return (
    <div className="mr-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.5177 3H12.5977C10.9409 3 9.59772 4.34315 9.59772 6V17.28C9.59772 18.9369 10.9409 20.28 12.5977 20.28H18.5177C20.1746 20.28 21.5177 18.9369 21.5177 17.28V6C21.5177 4.34315 20.1746 3 18.5177 3ZM11.1377 11H11.9577C12.2339 11 12.4577 11.2239 12.4577 11.5V11.82C12.4577 12.0961 12.2339 12.32 11.9577 12.32H11.1377V11ZM18.5177 18.8C19.3461 18.8 20.0177 18.1284 20.0177 17.3V6C20.0177 5.17157 19.3461 4.5 18.5177 4.5H12.5977C11.7693 4.5 11.0977 5.17157 11.0977 6V9.48H11.9177C13.0223 9.48 13.9177 10.3754 13.9177 11.48V11.8C13.9177 12.9046 13.0223 13.8 11.9177 13.8H11.0977V17.3C11.0977 18.1284 11.7693 18.8 12.5977 18.8H18.5177Z" fill="#212121"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.00772 6.42C1.88724 4.68248 3.19113 3.17319 4.92772 3.04C6.65648 3.18862 7.94452 4.6995 7.81772 6.43V15.7C7.82907 16.181 7.69677 16.6545 7.43772 17.06L5.56772 19.95C5.43526 20.1875 5.18464 20.3347 4.91272 20.3347C4.6408 20.3347 4.39018 20.1875 4.25772 19.95L2.38772 17.06C2.13455 16.6519 2.00274 16.1802 2.00772 15.7V6.42ZM6.16773 16.24C6.26817 16.078 6.32022 15.8906 6.31773 15.7L6.33773 6.42C6.34772 5.4 5.69773 4.54 4.92772 4.54C4.16772 4.54 3.51772 5.42 3.51772 6.42V15.7C3.5092 15.8887 3.55427 16.0759 3.64772 16.24L4.90772 18.24L6.16773 16.24Z" fill="#212121"/>
        <path d="M4.91773 5.18C4.50351 5.18 4.16773 5.51579 4.16773 5.93V9.39C4.16773 9.80421 4.50351 10.14 4.91773 10.14C5.33194 10.14 5.66773 9.80421 5.66773 9.39V5.93C5.67044 5.73027 5.5923 5.53792 5.45105 5.39667C5.30981 5.25543 5.11746 5.17728 4.91773 5.18Z" fill="#212121"/>
        <path d="M18.2977 6.14H14.9477C14.5335 6.14 14.1977 6.47579 14.1977 6.89C14.1977 7.30421 14.5335 7.64 14.9477 7.64H18.2977C18.7119 7.64 19.0477 7.30421 19.0477 6.89C19.0477 6.47579 18.7119 6.14 18.2977 6.14Z" fill="#212121"/>
      </svg>
    </div>
  )
}

export default JobsLogo;