

export const data = [
  {
    author: "David Hungry",
    nickname: "@david44",
    description: "Hello everyNyan! How are u? Fine thank you",
    image_path: "images/image_1.jpg"
  },
  {
    author: "David Hungry",
    nickname: "@david44",
    description: "",
    image_path: "images/image_2.jpg"
  },
  {
    author: "Christina Volkova",
    nickname: "@chrisdmt",
    description: "",
    image_path: "images/Frame_1449235212.jpg"
  },
  {
    author: "Christina Volkova",
    nickname: "@chrisdmt",
    description: "",
    image_path: "images/Rectangle_39875.jpg"
  },
  {
    author: "Lord Vader",
    nickname: "@darkside",
    description: "",
    image_path: "images/Rectangle_39875.jpg"
  },
  {
    author: "Lord Vader",
    nickname: "@darkside",
    description: "",
    image_path: "images/Rectangle_39876.jpg"
  },
  {
    author: "Lord Vader",
    nickname: "@darkside",
    description: "",
    image_path: "images/Rectangle_39876.jpg"
  },
  {
    author: "Monkey D Elfus",
    nickname: "@redmagician",
    description: "",
    image_path: "images/Rectangle_39877.jpg"
  },
  {
    author: "Monkey D Elfus",
    nickname: "@redmagician",
    description: "",
    image_path: "images/pink.jpg"
  },
  {
    author: "Prof",
    nickname: "@moriarti",
    description: "",
    image_path: "images/moriarti.jpg"
  },
  {
    author: "Prof",
    nickname: "@moriarti",
    description: "",
    image_path: "images/green.jpg"
  },
]